// const config = {
//   graphQLUri: 'http://localhost:5001/graphql',
//   oauthUrl: 'http://localhost:4000/oauth/authorize',
//   logoutUrl: 'http://localhost:4000/logout?redirectTo=http://localhost:3000',
//   // client_id: '5fae5d8ab36eec993e61ad40',
//   client_id: 'iot_clientId',
//   redirect_uri: 'http://localhost:3000/login',
//   edit_profile_uri: 'http://localhost:4000/profile',
//   languages: ['it', 'en'],
//   defaultLanguage: 'it',
// };

const config = {
  graphQLUri: 'https://s-api-iot-deltaohm.geekcups.com/graphql',
  oauthUrl: 'https://s-login-deltaohm.geekcups.com/oauth/authorize',
  logoutUrl: 'https://s-login-deltaohm.geekcups.com/logout?redirectTo=https://s-iot-deltaohm.geekcups.com',
  client_id: 'gnmdu260o1t9ese9k69w',
  redirect_uri: 'https://s-iot-deltaohm.geekcups.com/login',
  edit_profile_uri: 'https://s-login-deltaohm.geekcups.com/profile',
  languages: ['en', 'it', 'es', 'fr'],
  defaultLanguage: 'en',
};

// const config = {
//   graphQLUri: 'https://iot-api.deltaohm.com/graphql',
//   oauthUrl: 'https://login.deltaohm.com/oauth/authorize',
//   logoutUrl: 'https://login.deltaohm.com/logout?redirectTo=https://iotcloud.deltaohm.com',
//   client_id: 'rzbufficglhcgpmulezu',
//   redirect_uri: 'https://iotcloud.deltaohm.com/login',
//   edit_profile_uri: 'https://login.deltaohm.com/profile',
//   languages: ['en', 'it', 'es', 'fr'],
//   defaultLanguage: 'en',
// };

export default config;
