/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReactECharts from 'echarts-for-react';
import {
  sub, add, formatISO, startOfDay, endOfDay,
} from 'date-fns';
import {
  Typography,
  Grid,
  FormControlLabel,
  FormGroup,
  Checkbox,
} from '@material-ui/core';
import {
  Loader,
  GraphQLErrorWrapper,
} from '../../generic';
import {
  MeasuresFilter,
} from '../components';
import {
  formatLocale,
  getTimezoneOffsetCorrection,
} from '../../utils/date';
import { GroupContext } from '../../group/GroupContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import {
  GROUP_DEVICE_MEASURES_QUERY,
  APPLICATION_DEVICE_MEASURES_QUERY,
} from '../queries';
import { useMeasureTypes } from '../../measureTypes';

const colors = [
  '#0074D9',
  '#7FDBFF',
  '#39CCCC',
  '#3D9970',
  '#2ECC40',
  '#01FF70',
  '#FFDC00',
  '#FF851B',
  '#FF4136',
  '#85144b',
  '#F012BE',
  '#B10DC9',
  '#AAAAAA',
  '#001f3f',
];

const shapesPath = {
  triangleDown: 'M150 0 L75 200 L225 200 Z',
  triangleUp: 'M150 200 L75 0 L225 0 Z',
};

const MeasureMonitorChartTab = (props) => {
  const { device } = props;
  const { timezone } = device;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { deviceId } = useParams();

  const { convertToString } = useMeasureTypes();

  const correctionOffset = React.useMemo(() => getTimezoneOffsetCorrection(timezone), [timezone]);
  const NOW = new Date();

  const [filter, setFilter] = React.useState({
    from: sub(startOfDay(NOW), { seconds: correctionOffset }),
    to: sub(endOfDay(NOW), { seconds: correctionOffset }),
    step: 'day',
  });

  const [multiColor, setMulticolor] = React.useState(true);
  const [hideMarkers, setHideMarkers] = React.useState(false);

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICE_MEASURES_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICE_MEASURES_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return {
        groupId: group.id,
        id: deviceId,
        measureFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    if (application) {
      return {
        applicationId: application.id,
        id: deviceId,
        measureFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    return null;
  }, [group, application, deviceId, filter.from, filter.to]);

  const {
    error,
    loading,
    data,
  } = useQuery(QUERY, {
    variables: queryVariables,
  });

  const measures = React.useMemo(() => {
    if (!loading && !error) {
      const { viewer } = data;
      const parent = viewer.application ? viewer.application : viewer.group;
      const { measures: measuresQuery, measureChannels } = parent.device;
      const measureChannelHash = measureChannels
        .filter((mc) => mc.unitOfMeasure.id !== 255)
        .reduce((acc, item) => (
          {
            ...acc,
            [item.channel.toString()]: item,
          }
        ), {});
      const validChannels = Object.keys(measureChannelHash);

      console.time('calculating');
      const result = measuresQuery
        .filter((m) => validChannels.includes(m.channel.toString()))
        .map((m) => ({
          ...m,
          timestamp: formatISO(add(new Date(m.timestamp), { seconds: correctionOffset })),
        }))
        .map((m) => {
          let label = measureChannelHash[m.channel]
            ? measureChannelHash[m.channel].nameOverride
              || measureChannelHash[m.channel].userCode
              || t(`enums.measureTypes.${measureChannelHash[m.channel].measureType.title}`)
            : 'Unknown';
          if (m.channel <= 12) {
            label = `${t('common.CH')} ${m.channel} ${label}`;
          }
          label = `${label} (${measureChannelHash[m.channel].conversion.unitOfMeasure.label})`;
          return {
            ...m,
            realValue: parseFloat(convertToString(
              m.realValue,
              measureChannelHash[m.channel].measureType,
              measureChannelHash[m.channel].conversion,
              m.resolution,
            )),
            label,
          };
        }).reverse();
      console.timeEnd('calculating');
      return result;
    }
    return [];
  }, [convertToString, loading, error, data, correctionOffset, t]);

  const parameters = React.useMemo(() => {
    if (measures.length > 0) {
      let labels = [...new Set(measures.map(((m) => m.label)))];
      if (labels[0].startsWith('RSSI')) {
        labels = [...labels.slice(1), labels[0]];
      }
      if (labels[0].startsWith('SNR')) {
        labels = [...labels.slice(1), labels[0]];
      }

      return labels
        .reduce((acc, label) => ([
          ...acc,
          label,
          `${label}Alarm`,
        ]
        ), []);
    }
    return [];
  }, [measures]);

  const chartDataset = React.useMemo(() => {
    if (measures.length > 0) {
      console.time('calculating chartDataset');

      const chartMeasuresMap = new Map();
      measures.forEach((measure) => {
        chartMeasuresMap.set(measure.timestamp, {
          ...chartMeasuresMap.get(measure.timestamp),
          [measure.label]: parseFloat(measure.realValue),
          [`${measure.label}Alarm`]: parseFloat(measure.alarm),
        });
      });
      const chartMeasures = [...chartMeasuresMap].map(([key, value]) => ({
        timestamp: key,
        ...value,
      }));

      const chartMeasuresLength = chartMeasures.length;
      if (chartMeasuresLength > 0) {
        if (chartMeasures[0].timestamp !== filter.from) {
          chartMeasures.unshift({
            timestamp: formatISO(add(new Date(filter.from), { seconds: correctionOffset })),
            // timestamp: filter.from,
          });
        }
        if (chartMeasures[chartMeasuresLength - 1].timestamp !== filter.to) {
          chartMeasures.push({
            timestamp: formatISO(add(new Date(filter.to), { seconds: correctionOffset })),
            // timestamp: filter.to,
          });
        }
      }

      console.timeEnd('calculating chartDataset');

      return {
        dimensions: ['timestamp', ...parameters],
        source: chartMeasures,
      };
    }
    return null;
  }, [measures, parameters, filter.from, filter.to, correctionOffset]);

  const singleChartOptions = React.useMemo(() => {
    if (chartDataset && measures) {
      return parameters
        .filter((label) => !label.endsWith('Alarm'))
        .map((label, index) => {
          const renderArrow = (param, api) => {
            const point = api.coord([
              api.value('timestamp'),
              api.value(label),
            ]);
            const pathSize = 18;
            let pathData;
            switch (api.value(`${label}Alarm`)) {
              case 4:
                pathData = shapesPath.triangleUp;
                break;
              case 5:
                pathData = shapesPath.triangleUp;
                break;
              case 6:
                pathData = shapesPath.triangleDown;
                break;
              case 7:
                pathData = shapesPath.triangleDown;
                break;
              default:
                pathData = '';
                break;
            }
            return {
              type: 'path',
              shape: {
                pathData,
                x: -pathSize / 2,
                y: -pathSize / 2,
                width: pathSize,
                height: pathSize,
              },
              position: point,
              style: api.style({
                stroke: '#FF0000',
                fill: '#FF0000',
                color: '#FF0000',
                lineWidth: 1,
              }),
            };
          };

          const customSerieStyle = {
            lineStyle: {},
            itemStyle: {},
          };
          if (multiColor) customSerieStyle.lineStyle.color = colors[index];
          if (multiColor) customSerieStyle.itemStyle.color = colors[index];
          if (hideMarkers) customSerieStyle.symbol = 'none';

          return {
            title: {
              text: label,
              left: 'center',
            },
            toolbox: {
              show: true,
              feature: {
                dataZoom: {
                  yAxisIndex: 'none',
                },
                // dataView: { readOnly: false },
                // magicType: { type: ['line', 'bar', 'tiled'] },
                restore: {},
                saveAsImage: {},
              },
            },
            // dataZoom: [
            //   {
            //     show: true,
            //     realtime: true,
            //     start: 0,
            //     end: 100,
            //     xAxisIndex: [0, 1],
            //   },
            //   {
            //     type: 'inside',
            //     realtime: true,
            //     start: 0,
            //     end: 100,
            //     xAxisIndex: [0, 1],
            //   },
            // ],
            tooltip: {
              trigger: 'axis',
              formatter(params) {
                const rows = [
                  `${formatLocale(new Date(params[0].value.timestamp), 'yyyy/MM/dd HH:mm:ss', locale)}`,
                ];
                if (params[0].value[`${label}Alarm`] >= 4) {
                  rows.push(t(`enums.alarms.level${params[0].value[`${label}Alarm`]}`));
                }
                else {
                  rows.push(`${label}: ${params[0].value[label]}`);
                }
                return rows.join('<br>');
              },
            },
            xAxis: {
              type: 'time',
            },
            yAxis: {
              // boundaryGap: ['10%', '10%'], // sembra non aver effetto
              min: (value) => (value.min - ((value.max - value.min) * 0.1)),
              max: (value) => (value.max + ((value.max - value.min) * 0.1)),
              axisLabel: {
                showMaxLabel: false,
                showMinLabel: false,
                // eslint-disable-next-line no-unused-vars,no-shadow
                formatter: (value, index) => (value.toLocaleString('standard').replace(/,/g, '')),
              },
            },
            dataset: chartDataset,
            series: [{
              name: label,
              type: 'line',
              animation: true,
              sampling: 'lttb',
              symbolSize: 4,
              encode: {
                x: 'timestamp',
                y: label,
              },
              ...customSerieStyle,
            },
            {
              type: 'custom',
              renderItem: renderArrow,
              encode: {
                x: 'timestamp',
                y: label,
              },
              z: 10,
            }],
          };
        });
    }
    return null;
  }, [chartDataset, measures, parameters, multiColor, hideMarkers, locale, t]);

  const handleChangeFilter = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }
  if (loading) {
    return (<Loader loading={loading} />);
  }

  const renderCharts = () => (
    <Grid container spacing={10} style={{ paddingTop: 40, paddingBottom: 40 }}>
      {singleChartOptions && singleChartOptions.map((singleChartOption) => (
        <Grid item xs={12} key={singleChartOption.series[0].name}>
          <ReactECharts
            option={singleChartOption}
            style={{ height: 500 }}
            notMerge
            lazyUpdate
          />
        </Grid>
      ))}
    </Grid>
  );

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <Grid container spacing={4} direction="row" justify="center" alignItems="center">
          <FormGroup row>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={multiColor}
                  onChange={(event) => setMulticolor(event.target.checked)}
                  name="multiColor"
                />
              )}
              label={t('common.multiColor')}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={hideMarkers}
                  onChange={(event) => setHideMarkers(event.target.checked)}
                  name="hideMarkers"
                />
              )}
              label={t('common.hideMarkers')}
            />
          </FormGroup>
          <MeasuresFilter
            from={filter.from}
            to={filter.to}
            step={filter.step}
            timezone={timezone}
            onFormSubmit={(newFilter) => handleChangeFilter(newFilter)}
          />
        </Grid>
      </Grid>
      {measures.length > 0
        ? renderCharts()
        : (
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="center">{t('common.measuresNoData')}</Typography>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

MeasureMonitorChartTab.propTypes = propTypes;

export default MeasureMonitorChartTab;
