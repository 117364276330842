import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';

import {
  Loader,
  GraphQLErrorWrapper,
} from '../generic';

import { MEASURE_TYPES_QUERY } from './queries';

export const MeasureTypesContext = React.createContext();

export const MeasureTypesProvider = (props) => {
  const { children } = props;

  const {
    error,
    loading,
    data,
  } = useQuery(MEASURE_TYPES_QUERY);

  const measureTypes = React.useMemo(() => {
    if (!loading && !error) {
      return data.viewer.measureTypes;
    }
    return null;
  }, [error, data, loading]);

  if (error) {
    return (
      <GraphQLErrorWrapper error={error} />
    );
  }

  if (loading) {
    return (
      <Loader loading={loading} />
    );
  }

  return (
    <MeasureTypesContext.Provider value={{ measureTypes }}>{children}</MeasureTypesContext.Provider>
  );
};

const propTypes = {
  children: PropTypes.node.isRequired,
};

MeasureTypesProvider.propTypes = propTypes;

export const { Consumer: MeasureTypesConsumer } = MeasureTypesProvider;
