import { gql } from '@apollo/client';

export const MEASURE_TYPE_DETAIL_FRAGMENT = gql`
  fragment MeasureTypeDetailFragment on MeasureType {
    id
    unitOfMeasure {
      id
      label
    }
    resolution
    qualifier {
      id
      label
    }
    title
    reportLabel
    icon
    conversions {
      unitOfMeasure {
        id
        label
      }
      scale
      offset
      format
      isSI
    }
  }
`;

export const MEASURE_TYPES_QUERY = gql`
  query measureTypesQuery {
    viewer {
      measureTypes {
        ...MeasureTypeDetailFragment
      }
    }
  }
  ${MEASURE_TYPE_DETAIL_FRAGMENT}
`;
