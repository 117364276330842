import React from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CardWrapper,
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';

import DeviceForm from './DeviceForm';
import DeviceChannels from './DeviceChannels';

import {
  DEVICE_UPDATE_MUTATION,
  DEVICE_UPDATE_MEASURE_CHANNEL_CONVERSION_MUTATION,
} from '../queries';

const DeviceEditCard = (props) => {
  const { device, canEdit } = props;
  const { t } = useTranslation('translations');
  const { deviceId } = useParams();
  const [successOpen, setSuccessOpen] = React.useState(false);

  const [
    parentUpdateMutationAction,
    {
      loading: parentUpdateLoading,
      error: parentUpdateError,
    },
  ] = useMutation(DEVICE_UPDATE_MUTATION);

  const [
    updateMeasureChannelConversionMutationAction,
    {
      loading: updateMeasureChannelConversionLoading,
      error: updateMeasureChannelConversionError,
    },
  ] = useMutation(DEVICE_UPDATE_MEASURE_CHANNEL_CONVERSION_MUTATION);

  const handleFormSubmit = async (data) => {
    try {
      const result = await parentUpdateMutationAction({
        variables: {
          input: {
            ...data,
            id: deviceId,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handleChangeConversion = async (data) => {
    try {
      const result = await updateMeasureChannelConversionMutationAction({
        variables: {
          input: {
            id: deviceId,
            ...data,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  return (
    <CardWrapper
      cardTitle={t('devices.edit.title')}
      cardBody={
        (
          <>
            <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
            <GraphQLErrorWrapper error={parentUpdateError || updateMeasureChannelConversionError} />
            <Loader loading={parentUpdateLoading || updateMeasureChannelConversionLoading} />
            <DeviceForm
              device={device}
              canEdit={canEdit}
              onFormSubmit={handleFormSubmit}
            />
            <DeviceChannels
              device={device}
              onFormSubmit={handleFormSubmit}
              onChangeConversion={handleChangeConversion}
            />
          </>
        )
      }
    />
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
  canEdit: PropTypes.bool.isRequired,
};

DeviceEditCard.propTypes = propTypes;

export default DeviceEditCard;
