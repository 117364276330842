import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import format from 'date-fns/format';

import {
  Button,
  TextField,
  Typography,
  Grid,
  FormControl,
  FormLabel,
  Checkbox,
  MenuItem,
  Select,
  Divider,
} from '@material-ui/core';
import {
  Controller,
  useForm,
} from 'react-hook-form';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import {
  getLocale,
} from '../../utils/date';
import {
  GraphQLErrorWrapper,
  Loader,
  SuccessSnackBar,
} from '../../generic';
import {
  RF_LOG_INTERVALS,
  MEASURE_INTERVALS,
} from '../../enum';

import {
  DEVICE_SEND_COMMAND_MUTATION,
} from '../queries';

const ConfigurationCard = (props) => {
  const {
    device,
  } = props;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const [successOpen, setSuccessOpen] = React.useState(false);

  const [startRange, setStartRange] = React.useState(new Date());
  const [endRange, setEndRange] = React.useState(new Date());

  const [
    sendCommandMutationAction,
    {
      loading,
      error,
    },
  ] = useMutation(DEVICE_SEND_COMMAND_MUTATION);

  const {
    control,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ...device,

    },
  });

  const handleFormSubmit = async (data) => {
    const input = {
      ...data,
      isAck: true,
      port: 100,
      id: device.id,
      enqueue: true,
    };
    try {
      const result = await sendCommandMutationAction({
        variables: {
          input: {
            ...input,
          },
        },
      });
      if (result) setSuccessOpen(true);
    }
    catch (e) { } // eslint-disable-line
  };

  const handleSendForm = async (values) => {
    let payload = 'FS;';
    payload = `${payload}${values.userCode};`;
    payload = `${payload}${values.rainTip};`;
    payload = `${payload}${values.RFLogInterval};`;
    payload = `${payload}${values.measureInterval};`;
    payload = `${payload}${values.protocolType};`;
    payload = `${payload}${values.timeSynchronization ? 1 : 0};`;
    payload = `${payload}${values.loggingActive ? 1 : 0};`;
    payload = `${payload}${values.cyclicLogging ? 1 : 0};`;
    payload = `${payload}${values.buzzerAlarm ? 1 : 0}`;

    const data = {
      command: 'FS',
      param: payload,
    };
    await handleFormSubmit(data);
  };

  const handleRefreshAllInfo = async () => {
    const data = {
      command: 'REFRESH_ALL_INFO',
    };
    await handleFormSubmit(data);
  };

  const handleTestBuzzer = async () => {
    const data = {
      command: 'TEST_BUZZER',
    };
    await handleFormSubmit(data);
  };
  const handleResetPendings = async () => {
    const data = {
      command: 'IGNORE_PENDING_COMMANDS',
    };
    await handleFormSubmit(data);
  };

  const handleHistoryBetweenDatetimeSubmit = async () => {
    const timeFormat = 'yyMMddHHmmSS';
    const data = `LU;${format(startRange, timeFormat)};${format(endRange, timeFormat)};1;1;5`;
    await handleFormSubmit({
      command: 'GENERIC',
      param: data,
    });
  };

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }
  if (loading) {
    return (<Loader loading={loading} />);
  }

  const { pendingCommands } = device;

  return (
    <>
      <SuccessSnackBar open={successOpen} setOpen={setSuccessOpen} message="common.operationSuccess" />
      <form onSubmit={handleSubmit(handleSendForm)}>
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <Controller
              as={(
                <TextField
                  label={t('common.userCode')}
                  placeholder={t('common.userCode')}
                  fullWidth
                  autoCapitalize="none"
                  required
                  inputProps={{
                    maxLength: 20,
                    minLength: 1,
                    pattern: '^[a-zA-Z0-9 _-]*$',
                  }}
                />
            )}
              id="userCode"
              defaultValue=""
              name="userCode"
              control={control}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              as={(
                <TextField
                  label={t('common.rainTip')}
                  placeholder={t('common.rainTip')}
                  fullWidth
                  autoCapitalize="none"
                  type="number"
                  inputProps={{
                    max: 1599,
                    min: 50,
                    minLength: 1,
                  }}
                  step="1"
                />
            )}
              id="rainTip"
              name="rainTip"
              control={control}
            />
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <FormLabel>{t('common.RFLogInterval')}</FormLabel>
              <Controller
                as={(
                  <Select
                    labelId="RFLogIntervals"
                    fullWidth
                    required
                  >
                    <MenuItem key="null" value=""> </MenuItem>
                    {Object.keys(RF_LOG_INTERVALS)
                      .map((key) => (
                        <MenuItem key={key} value={RF_LOG_INTERVALS[key]}>
                          {t(`enums.intervals.${key}`)}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                id="RFLogInterval"
                name="RFLogInterval"
                defaultValue=""
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <FormLabel>{t('common.measureInterval')}</FormLabel>
              <Controller
                as={(
                  <Select
                    labelId="measureIntervals"
                    fullWidth
                    required
                  >
                    <MenuItem key="null" value=""> </MenuItem>
                    {Object.keys(MEASURE_INTERVALS)
                      .map((key) => (
                        <MenuItem key={key} value={MEASURE_INTERVALS[key]}>
                          {t(`enums.intervals.${key}`)}
                        </MenuItem>
                      ))}
                  </Select>
                )}
                id="measureInterval"
                name="measureInterval"
                defaultValue=""
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl fullWidth>
              <FormLabel>{t('common.protocolType')}</FormLabel>
              <Controller
                as={(
                  <Select
                    labelId="protocolTypes"
                    fullWidth
                    required
                  >
                    <MenuItem key="null" value=""> </MenuItem>
                    <MenuItem key="Cayenne" value={0}>Cayenne LPP</MenuItem>
                    <MenuItem key="Proprietary" value={1}>Proprietary protocol</MenuItem>
                  </Select>
                )}
                id="protocolType"
                name="protocolType"
                defaultValue={1}
                control={control}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl>
              <FormLabel>{t('common.timeSynchronization')}</FormLabel>
              <Controller
                name="timeSynchronization"
                control={control}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl>
              <FormLabel>{t('common.loggingActive')}</FormLabel>
              <Controller
                name="loggingActive"
                control={control}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl>
              <FormLabel>{t('common.cyclicLogging')}</FormLabel>
              <Controller
                name="cyclicLogging"
                control={control}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item style={{ minWidth: 200 }}>
            <FormControl>
              <FormLabel>{t('common.buzzerAlarm')}</FormLabel>
              <Controller
                name="buzzerAlarm"
                control={control}
                render={(checkboxProps) => (
                  <Checkbox
                    onChange={(e) => checkboxProps.onChange(e.target.checked)}
                    checked={checkboxProps.value}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ padding: 15 }}>
            <Button
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={pendingCommands.includes('FS')}
            >
              <Typography>{t('common.send')}</Typography>
            </Button>

          </Grid>
        </Grid>
      </form>
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Divider />
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          disabled={pendingCommands.includes('REFRESH_ALL_INFO')}
          onClick={handleRefreshAllInfo}
        >
          <Typography>{t('common.refreshAllInfo')}</Typography>
        </Button>

      </Grid>
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Divider />
        <Button
          fullWidth
          type="submit"
          color="primary"
          variant="contained"
          disabled={pendingCommands.includes('TEST_BUZZER')}
          onClick={handleTestBuzzer}
        >
          <Typography>{t('common.testBuzzer')}</Typography>
        </Button>
      </Grid>
      {pendingCommands.length > 0 && (
        <Grid item xs={12} style={{ paddingTop: 15 }}>
          <Divider />
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleResetPendings}
          >
            <Typography>{t('common.ignorePendingCommands')}</Typography>
          </Button>

        </Grid>
      )}
      <Grid item xs={12} style={{ paddingTop: 15 }}>
        <Grid container spacing={4} direction="row" justify="center" alignItems="center">
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
              <KeyboardDateTimePicker
                style={{ height: 30 }}
                value={startRange}
                onChange={setStartRange}
                showTodayButton
                todayLabel={t('common.today')}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale(locale)}>
              <KeyboardDateTimePicker
                value={endRange}
                onChange={setEndRange}
                showTodayButton
                todayLabel={t('common.today')}
                okLabel={t('common.ok')}
                cancelLabel={t('common.cancel')}
                ampm={false}
                format="yyyy/MM/dd HH:mm"
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => handleHistoryBetweenDatetimeSubmit(startRange, endRange)}
            >
              <Typography>
                {t('common.askHistoricalData')}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

ConfigurationCard.propTypes = propTypes;

export default ConfigurationCard;
