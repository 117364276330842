/* eslint-disable import/prefer-default-export */
import React from 'react';
import { MeasureTypesContext } from './MeasureTypesContext';

export const useMeasureTypes = () => {
  const { measureTypes } = React.useContext(MeasureTypesContext);

  const convertTo = (value, conversion) => (value * conversion.scale + conversion.offset);

  const convertToString = (value, measureType, conversion, resolution) => {
    const result = convertTo(value, conversion);
    return result.toFixed(resolution);
  };

  return {
    measureTypes,
    convertTo,
    convertToString,
  };
};
