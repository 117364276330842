import { gql } from '@apollo/client';

import { MEASURE_TYPE_DETAIL_FRAGMENT } from '../measureTypes/queries';

export const MEASURE_CHANNEL_FRAGMENT = gql`
  fragment MeasureChannelFragment on MeasureChannel {
    channel
    measureType {
      ...MeasureTypeDetailFragment
    }
    unitOfMeasure {
      id
      label
    }
    conversion {
      unitOfMeasure {
        id
        label
      }
      scale
      offset
      format
      isSI
    }
    qualifier {
      id
      label
    }
    resolution
    nameOverride
    userCode
    realLastValue
    alarm
  }
  ${MEASURE_TYPE_DETAIL_FRAGMENT}
`;

export const DEVICE_FRAGMENT = gql`
  fragment DeviceFragment on Device {
    id
    timezone
    rssi
    snr
    dataRate
    lastBatteryLevel
    lastActivityTimestamp
    measureChannels {
      ...MeasureChannelFragment
    }
  }
  ${MEASURE_CHANNEL_FRAGMENT}
`;

export const DEVICE_FRAGMENT_WITH_MEASURES = gql`
  fragment DeviceWithMeasuresFragment on Device {
    id
    timezone
    measures(filter: $measureFilter) 
    measureChannels {
      ...MeasureChannelFragment
    }
  }
  ${MEASURE_CHANNEL_FRAGMENT}
`;

export const GROUP_DEVICE_MEASURES_QUERY = gql`
  query groupDeviceMeasuresQuery($groupId: ID!, $id: ID!, $measureFilter: MeasureFilter!) {
    viewer {
      group(id: $groupId) {
        device(id: $id) {
          ...DeviceWithMeasuresFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT_WITH_MEASURES}
`;

export const GROUP_DEVICE_WITH_MEASURE_CHANNELS_QUERY = gql`
  query groupDeviceMeasureChannelsQuery($groupId: ID!, $id: ID!) {
    viewer {
      group(id: $groupId) {
        device(id: $id) {
          ...DeviceFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT}
`;

export const APPLICATION_DEVICE_MEASURES_QUERY = gql`
  query applicationDeviceMeasuresQuery($applicationId: ID!, $id: ID!, $measureFilter: MeasureFilter!) {
    viewer {
      application(id: $applicationId) {
        device(id: $id) {
          ...DeviceWithMeasuresFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT_WITH_MEASURES}
`;

export const APPLICATION_DEVICE_WITH_MEASURE_CHANNELS_QUERY = gql`
  query applicationDeviceMeasureChannelsQuery($applicationId: ID!, $id: ID!) {
    viewer {
      application(id: $applicationId) {
        device(id: $id) {
          ...DeviceFragment
        }
      }
    }
  }
  ${DEVICE_FRAGMENT}
`;
