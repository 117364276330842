import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  sub, startOfDay, endOfDay,
} from 'date-fns';
import {
  Grid,
  Typography,
} from '@material-ui/core';
import {
  Loader,
  GraphQLErrorWrapper,
} from '../../generic';
import {
  MeasuresFilter,
  MeasuresTable,
} from '../components';
import {
  formatLocale,
  getTimezoneOffsetCorrection,
} from '../../utils/date';
import { GroupContext } from '../../group/GroupContext.jsx';
import { ApplicationContext } from '../../application/ApplicationContext.jsx';

import {
  GROUP_DEVICE_MEASURES_QUERY,
  APPLICATION_DEVICE_MEASURES_QUERY,
} from '../queries';
import { useMeasureTypes } from '../../measureTypes';

const DeviceMonitorTableTab = (props) => {
  const { device } = props;
  const { timezone } = device;
  const { t, i18n } = useTranslation('translations');
  const locale = i18n.language;
  const { deviceId } = useParams();

  const { convertToString } = useMeasureTypes();

  const correctionOffset = React.useMemo(() => getTimezoneOffsetCorrection(timezone), [timezone]);
  const NOW = new Date();

  const [filter, setFilter] = React.useState({
    from: sub(startOfDay(NOW), { seconds: correctionOffset }),
    to: sub(endOfDay(NOW), { seconds: correctionOffset }),
    step: 'day',
  });

  const groupContext = React.useContext(GroupContext);
  const applicationContext = React.useContext(ApplicationContext);

  const group = React.useMemo(() => {
    if (groupContext && groupContext.group) {
      return groupContext.group;
    }
    return null;
  }, [groupContext]);

  const application = React.useMemo(() => {
    if (applicationContext && applicationContext.application) {
      return applicationContext.application;
    }
    return null;
  }, [applicationContext]);

  const QUERY = React.useMemo(() => {
    if (group) {
      return GROUP_DEVICE_MEASURES_QUERY;
    }
    if (application) {
      return APPLICATION_DEVICE_MEASURES_QUERY;
    }
    return null;
  }, [group, application]);

  const queryVariables = React.useMemo(() => {
    if (group) {
      return {
        groupId: group.id,
        id: deviceId,
        measureFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    if (application) {
      return {
        applicationId: application.id,
        id: deviceId,
        measureFilter: {
          from: filter.from,
          to: filter.to,
        },
      };
    }
    return null;
  }, [group, application, deviceId, filter.from, filter.to]);

  const {
    error,
    loading,
    data,
  } = useQuery(QUERY, {
    variables: queryVariables,
  });

  const getAlarmString = (alarm) => {
    let alarmString;
    switch (alarm) {
      case 4:
        alarmString = 'Underflow';
        break;
      case 5:
        alarmString = 'Not init';
        break;
      case 6:
        alarmString = 'Overflow';
        break;
      case 7:
        alarmString = 'Error';
        break;
      default:
        alarmString = null;
        break;
    }
    return alarmString;
  };

  const tableData = React.useMemo(() => {
    if (!loading && !error) {
      // console.time('calculating resultColumnsHeaders');
      const { viewer } = data;
      const parent = viewer.application ? viewer.application : viewer.group;
      const { measures, measureChannels } = parent.device;
      const measureChannelHash = measureChannels
        .filter((mc) => mc.measureType.id !== 255)
        .reduce((acc, item) => (
          {
            ...acc,
            [item.channel.toString()]: item,
          }
        ), {});
      const measureChannelArray = Object.keys(measureChannelHash).map((channel) => ({
        ...measureChannelHash[channel],
        labelChannel: channel <= 12 ? `${t('common.CH')} ${channel}` : null,
        labelMeasureType: measureChannelHash[channel].nameOverride
          || measureChannelHash[channel].userCode
          || t(`enums.measureTypes.${measureChannelHash[channel].measureType.title}`),
        labelUnitOfMeasure: `(${measureChannelHash[channel].conversion.unitOfMeasure.label})`,
      }));
      // sposto rssi e snr dal fondo all'inizio della tabella
      const resultColumnsHeaders = [
        ...measureChannelArray.slice(measureChannelArray.length - 2, measureChannelArray.length),
        ...measureChannelArray.slice(0, measureChannelArray.length - 2),
      ];
      // console.timeEnd('calculating resultColumnsHeaders');

      if (measures.length > 0) {
        // console.time('calculating MAP rows');
        const measuresRowsMap = new Map();
        measures.forEach((measure) => {
          if (measureChannelHash[measure.channel.toString()]) {
            measuresRowsMap.set(
              measure.timestamp,
              {
                ...measuresRowsMap.get(measure.timestamp),
                [measure.channel]: (measure.alarm && measure.alarm > 3)
                  ? getAlarmString(measure.alarm)
                  : convertToString(
                    measure.realValue,
                    measureChannelHash[measure.channel.toString()].measureType,
                    measureChannelHash[measure.channel.toString()].conversion,
                    measure.resolution,
                  ),
              },
            );
          }
        });
        const measuresRows = [...measuresRowsMap].map(([key, value]) => ({
          timestamp: formatLocale(new Date(key), 'yyyy/MM/dd HH:mm:ss', locale, timezone),
          ...value,
        }));
        // console.timeEnd('calculating MAP rows');

        let downloadFilename = `${device.devEUI}`;
        downloadFilename = `${downloadFilename}${formatLocale(filter.from, 'yyyyMMdd_HHmmss', locale, timezone)}`;
        downloadFilename = `${downloadFilename}_${formatLocale(filter.to, 'yyyyMMdd_HHmmss', locale, timezone)}`;
        downloadFilename = `${downloadFilename}.csv`;

        return {
          columnsHeaders: [
            {
              labelChannel: null,
              labelMeasureType: t('common.timestamp'),
              labelUnitOfMeasure: null,
            },
            ...resultColumnsHeaders,
          ],
          measuresRows,
          downloadFilename,
          timezone,
        };
      }
    }
    return {
      columnsHeaders: [],
      measuresRows: [],
      downloadFilename: 'test.csv',
      timezone: '00:00',
    };
  }, [
    convertToString,
    loading,
    error,
    data,
    t,
    device.devEUI,
    filter.from,
    filter.to,
    locale,
    timezone,
  ]);

  const handleChangeFilter = React.useCallback((newFilter) => {
    setFilter(newFilter);
  }, [setFilter]);

  if (error) {
    return <GraphQLErrorWrapper error={error} />;
  }
  if (loading) {
    return (<Loader loading={loading} />);
  }

  const {
    columnsHeaders,
    measuresRows,
    downloadFilename,
  } = tableData;

  return (
    <Grid container spacing={5}>
      <Grid item xs={12}>
        <MeasuresFilter
          from={filter.from}
          to={filter.to}
          step={filter.step}
          timezone={timezone}
          onFormSubmit={(newFilter) => handleChangeFilter(newFilter)}
        />
      </Grid>
      {measuresRows.length > 0
        ? (
          <MeasuresTable
            columnsHeaders={columnsHeaders}
            measuresRows={measuresRows}
            downloadFilename={downloadFilename}
          />
        )
        : (
          <Grid
            container
            justify="center"
            alignItems="center"
            spacing={6}
          >
            <Grid item xs={12}>
              <Typography variant="h6" component="h6" align="center">{t('common.measuresNoData')}</Typography>
            </Grid>
          </Grid>
        )}
    </Grid>
  );
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  device: PropTypes.object.isRequired,
};

DeviceMonitorTableTab.propTypes = propTypes;

export default DeviceMonitorTableTab;
